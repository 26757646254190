import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		cid:'',
	},
	mutations: {
		UPDATE_CID: (state, txt) => {
			//n是从组件中传来的参数
			state.cid = txt
		},
	},

})

export default store