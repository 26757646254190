<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import '@/css/include/hhrest.scss'
import '@/css/news/index.scss'
export default {
  name: "home",
  mounted() {
    // const url = window.location.href;
    // const params = this.$global.getUrlParams(url);
    // let type = params.type
    // var typeToCidMap = {
    //   'Entertainment': 5926863456,
    //   'Sports': 2646093994

    // };
    // let cid = typeToCidMap[type] || 5926863456;
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223'
    script.crossOrigin = 'anonymous';
    // script.setAttribute('data-ad-channel', cid);
    document.head.appendChild(script);
  },

};
</script>

  